import React from "react"
import styled, { keyframes } from "styled-components"
import IconCheck from "../../images/svgs/ic_check.svg"

const ToastNotification = () => {
  return (
    <ToastNotificationWrapper>
      <IconCheck /> Mensaje enviado con éxito
    </ToastNotificationWrapper>
  )
}

const slideInTop = keyframes`
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const ToastNotificationWrapper = styled.div`
  width: 255px;
  height: 48px;
  background: ${({ theme }) => theme.color.success};
  z-index: 999;
  top: 96px;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  border-radius: 16px;
  color: ${({ theme }) => theme.color.white.regular};
  ${({ theme }) => theme.font_size.xsmall};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${slideInTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  svg {
    margin-right: 8px;
  }
`

export default ToastNotification
