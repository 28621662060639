const theme = {
  font: {
    thin: `'Roboto'`,
    medium: `'MontBook'`,
    bold: `'MontBold'`,
    extraBold: `'MontHeavy'`,
    medium500: `'MontMedium'`
  },
  font_size: {
    xsmall: "font-size: 12px;",
    small: "font-size: 14px; line-height: 18px;",
    regular: "font-size: 16px;",
    regularr: "font-size: 18px; line-height: 20px;",
    medium: "font-size: 20px; line-height: 24px;",
    large: "font-size: 22px; line-height: 28px;",
    larger: "font-size: 24px; line-height: 30px;",
    xlarge: "font-size: 32px; line-height: 40px;",
    xlarger: "font-size: 40px; line-height: 48px;",
    xxlarge: "font-size: 48px; line-height: 53px;",
    xxxlarge: "font-size: 64px; line-height: 82px;",
  },
  color: {
    purple: "#4528B3",
    purpleLight: "#5B3BD3",
    purpleSemi: "#3D24A0",
    purpleDeep: "#37208F",
    purpleBright: "#7066FF",
    hoverPrimary: "#8076FF",
    hoverSecondary: "#E9E8FF",
    white: "#FFF",
    whiteopacity: "rgba(255,255,255,0.2)",
    hoverWhiteopacity: "rgba(233,232,255,0.4)",
    black: "#060411",
    sand: "#F6F5F0",
    greybg: "#F6F6F6",
    neutral: "#E0E0E0",
    grey: {
      grey01: "#E6E5E7",
      grey02: "#CDCCCF",
      grey03: "#B4B3B7",
      grey04: "#828187",
    },
    sun: "#FFCA42",
    sunMild: "#FFCF54",
    sunLight: "#FFDF78",
    jade: "#0CB293",
    jadeMild: "#16C0A0",
    jadeLight: "#7DE1C3",
    coral: "#FF7173",
    coralMild: "#FF7F81",
    coralLight: "#FFB9C1",
    prosegurYellow: "#FFD102",
    purples: {
      purple100: "#37208F",
      purple80: "#4528B3",
      purple60: "#5B3BD3",
      purple40: "#7066EF",
      purple30: "#BFBAFF",
      purple20: "#DCDAFF",
      purple10: "#ECEBFD",
      purple5: "#F6F5FF",
    },
    neutrals: {
      neutral100: "#060411",
      neutral80: "#828187",
      neutral60: "#B4B3B7",
      neutral40: "#C4C4C4",
      neutral20: "#E0E0E0",
      neutral10: "#F2F2F2",
      neutral5: "#F9F9F9",
      neutral0: "#FFFFFF",
    },
    greens: {
      green100: "#3EAD97",
      green80: "#7BDFCB",
      green60: "#A9E5DA",
      green40: "#B9EEE4",
      green30: "#E2F2EF",
      green20: "#D6F4EF",
      green10: "#DAFFF8",
    },
    pinks: {
      pink100: "#FF7173",
      pink80: "#F0A19F",
      pink60: "#FFB3B0",
      pink40: "#FFDDDC",
      pink20: "#FFE9E9",
    },
    yellows: {
      yellow100: "#FFD102",
      yellow80: "#EFE3A8",
      yellow60: "#FFFBE8",
      yellow40: "#F6F5F0",
    },
  },
  screen: {
    xs: "375px",
    sm: "768px",
    md: "1024px",
    lg: "1200px",
    xl: "1440px",
  },
}

export default theme
