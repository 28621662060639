import styled from "styled-components"

export const Container = styled.div`
  //max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  //padding: 0 24px;

  @media (min-width: ${props => props.theme.screen.md}) {
    //padding: 0 40px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    //padding: 0 155px;
  }
`

export const Section = styled.section`
  padding: 0;
  overflow: hidden;
  background-color: ${props => {
    switch (props.accent) {
      case "secondary":
        return props.theme.color.white.dark
      case "main":
        return props.theme.color.primary
      default:
        return "white"
    }
  }};

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 10px 0;
  }
`

export const HeaderWrapper = styled.header`
  margin-top: 55px;
`

export const FlexHeaderSections = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

export const ContainerStatics = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 188px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    max-width: 1024px;
    padding: 0 124px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    max-width: 768px;
    padding: 0 40px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    max-width: 375px;
    padding: 0 20px;
  }
`
export const TitleH1Statics = styled.h1`
  ${({ theme }) => theme.font_size.larger};
  color: ${({ theme }) => theme.color.primary};

  @media (max-width: ${props => props.theme.screen.lg}) {
    ${({ theme }) => theme.font_size.large};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    ${({ theme }) => theme.font_size.regular};
  }
`
export const TitlesStatics = styled.h2`
  ${({ theme }) => theme.font_size.larger};
  color: ${({ theme }) => theme.color.primary};
`
export const TextStatics = styled.p`
  ${({ theme }) => theme.font_size.regular};
  color: ${({ theme }) => theme.color.black.greydark};
  margin: 0;
  font-family: ${({ theme }) => theme.font.medium};

  @media (max-width: ${props => props.theme.screen.lg}) {
    ${({ theme }) => theme.font_size.small};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    ${({ theme }) => theme.font_size.xxsmall};
  }
`
export const LinkToDownload = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;

  > a {
    margin-left: 24px;
    font-family: ${({ theme }) => theme.font.medium};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 20px;
  }
`
export const MainTitleStatics = styled.h1`
  margin: 192px 0 0 0;
  color: ${({ theme }) => theme.color.primary};
  ${({ theme }) => theme.font_size.larger};

  @media (max-width: ${props => props.theme.screen.lg}) {
    ${({ theme }) => theme.font_size.large};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    ${({ theme }) => theme.font_size.regular};
    margin-top: 134px;
  }
`

export const SecondaryTitleStatics = styled.h2`
  margin: 40px 0 20px 0;
  color: ${({ theme }) => theme.color.black.greydark};
  ${({ theme }) => theme.font_size.regular};

  @media (max-width: ${props => props.theme.screen.lg}) {
    ${({ theme }) => theme.font_size.small};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    ${({ theme }) => theme.font_size.xsmall};
    margin-top: 20px;
  }
`

export const TextWithMarginStatics = styled(TextStatics)`
  ${({ theme }) => theme.font_size.small};
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    ${({ theme }) => theme.font_size.xsmall};
  }

  @media (min-width: ${props =>
      props.theme.screen.sm}) and (max-width: ${props =>
      props.theme.screen.md}) {
    ${({ theme }) => theme.font_size.xxsmall};
  }
`
export const TextListElementStatics = styled.li`
  ${({ theme }) => theme.font_size.small};
  color: ${({ theme }) => theme.color.black.greydark};
  font-family: ${({ theme }) => theme.font.medium};

  @media (max-width: ${props => props.theme.screen.lg}) {
    ${({ theme }) => theme.font_size.xsmall};
  }

  @media (min-width: ${props =>
      props.theme.screen.sm}) and (max-width: ${props =>
      props.theme.screen.md}) {
    ${({ theme }) => theme.font_size.xxsmall};
  }
`
