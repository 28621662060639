import React from "react"
import styled from "styled-components"
import theme from "../../styles/theme"
import IcArrow from "../../images/svgs/ic_16px_Next_White.svg"

const Button = ({
  children,
  to,
  btnType,
  onClick,
  rounded,
  fullwidth,
  disabled = false,
  external = true,
  arrow = false
}) => (
  <ButtonWrapper
    className="btn--wrapper"
    {...{ btnType, rounded, fullwidth, disabled }}
  >
    {to ? (
      <a
        href={to}
        target={external ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {children}
        {arrow ? (<div>&nbsp;</div>) : null}
        {arrow ? (<IcArrow/>) : null}
      </a>
    ) : (
      <button onClick={() => !disabled && onClick()}>{children}{arrow ? (<div>&nbsp;</div>) : null}{arrow ? (<IcArrow/>) : null}</button>
    )}
  </ButtonWrapper>
)

const BtnType = {
  primary: {
    color: {
      regular: theme.color.white,
    },
    background: {
      regular: theme.color.purple,
      hover: theme.color.hoverPrimary,
      pressed: theme.color.purpleDeep,
      disabled: theme.color.grey.grey01,
    },
  },
  secondary: {
    color: {
      regular: theme.color.purple,
    },
    border: {
      regular: "2px solid #4528B3",
    },
    background: {
      regular: theme.color.white,
      hover: theme.color.hoverSecondary,
      pressed: theme.color.white,
      disabled: theme.color.white,
    },
  },
  white: {
    color: {
      regular: theme.color.primary,
    },
    background: {
      regular: theme.color.white,
      hover: theme.color.grey.grey01,
      pressed: theme.color.white,
      disabled: theme.color.white,
    }
  },
  whiteopacity: {
    color: {
      regular: theme.color.white,
    },
    background: {
      regular: theme.color.whiteopacity,
      hover: theme.color.hoverWhiteopacity,
      pressed: theme.color.white,
      disabled: theme.color.white,
    },
    border: {
      regular: "2px solid #FFF",
    }
  },
  coral: {
    color: {
      regular: theme.color.white,
    },
    background: {
      regular: theme.color.coral,
      hover: theme.color.coralMild,
      pressed: theme.color.coral,
      disabled: theme.color.grey.grey01,
    },
  },
}

const ButtonWrapper = styled.div`
  width: ${({ fullwidth }) => (fullwidth ? "100%" : "fit-content")};

  a,
  button {
    color: ${({ btnType }) => BtnType[btnType].color.regular};
    background-color: ${({ btnType }) => BtnType[btnType].background.regular};
    border-radius: ${({ rounded }) => (rounded ? "60px" : "8px")};
    border: ${({ btnType }) => BtnType[btnType].border?.regular};
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: ${({ fullwidth }) => (fullwidth ? "center" : "start")};
    padding: 0 20px;
    cursor: pointer;
    transition: 0.4s;
    text-decoration: none;
    width: ${({ fullwidth }) => (fullwidth ? "100%" : "fit-content")};

    &:hover {
      background-color: ${({ btnType }) => BtnType[btnType].background.hover};
    }
    &:active {
      background-color: ${({ btnType }) => BtnType[btnType].background.pressed};
    }
    
    ${({ disabled, btnType }) =>
      disabled &&
      `background-color: ${BtnType[btnType].background.disabled} !important; cursor: not-allowed;`};
  }
`

export default Button
