//import React, {useEffect} from "react"
import React from "react"
import styled from "styled-components"
import { injectIntl, Link } from "gatsby-plugin-intl"
import LogoFooter from "../../../images/svgs/Icon_Durcal_88px.svg"
import IcFacebook from "../../../images/svgs/Icono_FB_40px.svg"
import IcYoutube from "../../../images/svgs/Icono_YT_40px.svg"
import IcTwitter from "../../../images/svgs/Icono_Twitter_40px.svg"
import IcInstagram from "../../../images/svgs/Icono_IG_40px.svg"
import { Container } from "../../global"
import { paths, checkLanguage } from "../../../utils"
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Popup}  from 'react-typeform-embed';
import Button from "../../common/button";

const Footer = ({ location, intl }) => {



if (new URLSearchParams(location.search)?.get("userId")) return (<div style={{marginBottom: "100px"}}/>);

return (
  <FooterWrapper id="footer" location={location}>
    <FooterColumnContainer>
      <FooterColumn className="logo">
        <LogoFooter/>
      </FooterColumn>
      <FooterColumn>
        <h6>{intl.formatMessage({ id: "footer_title_company" })}</h6>
        <ul>
          <li>
            <Link
              to={`/${checkLanguage(location, intl)}${
                paths[intl.locale].about
              }`}
            >
              {intl.formatMessage({ id: "footer_link_about" })}
            </Link>
          </li>
          <li>
            <a href="https://durcal.com/blog" target="_blank" rel="noreferrer">
              Blog
            </a>
          </li>
          <li>
            <Link
              to={`/${checkLanguage(location, intl)}${
                paths[intl.locale].contactPage
              }`}
            >
              {intl.formatMessage({ id: "footer_link_contact" })}
            </Link>
          </li>
          <li>
            <a
                href="https://www.notion.so/durcal/Durcal-Jobs-051d249f5905433699883597373a1c92"
                target="_blank"
                rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer_link_jobs" })}
            </a>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <h6>{intl.formatMessage({ id: "footer_title_legal" })}</h6>
        <ul>
          <li>
            <AnchorLink href="#FAQS" offset="200">
              {intl.formatMessage({ id: "footer_link_faqs" })}
            </AnchorLink>
          </li>
          <li>
            {/* <Link
              to={`/${checkLanguage(location, intl)}${
                paths[intl.locale].termsAndConditions
              }`}
            > */}
            <a
              href="https://static.durcal.com/tc/tc_es_web.html"
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer_link_terms" })}
            </a>
            {/* </Link> */}
          </li>
          <li>
            {/* <Link
              to={`/${checkLanguage(location, intl)}${
                paths[intl.locale].privacy
              }`}
            > */}
            <a
              href="https://static.durcal.com/pd/pd_es.html"
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer_link_privacy" })}
            </a>
            {/* </Link> */}
          </li>
          <li>
            {/* <Link
              to={`/${checkLanguage(location, intl)}${
                paths[intl.locale].privacy
              }`}
            > */}
            <a
                href="https://static.durcal.com/pd/CookiesPolicy.html"
                target="_blank"
                rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer_link_cookies" })}
            </a>
            {/* </Link> */}
          </li>

        </ul>
      </FooterColumn>
      <FooterColumn>
        <h6>{intl.formatMessage({ id: "footer_title_colaboration" })}</h6>
        <ul>
          <li>
            {intl.formatMessage({ id: "footer_title_colaboration_desc" })}
            <Popup id="LdBDlCDG" size={80}>
              <Button btnType="primary"
                     rounded
                     external={false}
                     arrow={false}
                     fullwidth={true}>{intl.formatMessage({ id: "footer_colaboration_button" })}</Button>
            </Popup>
          </li>

        </ul>
      </FooterColumn>
      <SocialMediaBottom>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/Durcal"
              title="Facebook Durcal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IcFacebook/>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/durcal_app"
              title="Twitter Durcal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IcTwitter/>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/durcal/"
              title="Instagram Durcal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IcInstagram/>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCAfGnljPedALBNgj07o_4FA"
              title="Youtube Durcal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IcYoutube/>
            </a>
          </li>
        </ul>
      </SocialMediaBottom>
    </FooterColumnContainer>

    <Copy>2024 © Durcal.com. All Rights Reserved.</Copy>
  </FooterWrapper>
)}

const FooterWrapper = styled.footer`
  position: relative;
  padding-bottom: 80px;
  z-index: 999;
  background-color: ${({ theme }) => theme.color.white};
  

  .footer-line {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-bottom: 40px;
  }
  
`

const FooterColumnContainer = styled(Container)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  
  .logo {
    margin-top: 40px;
    margin-bottom: 32px;

    @media (min-width: ${props => props.theme.screen.md}) {
      margin: 0;
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
  }
`
const SocialMediaBottom = styled.div`
  //margin: 56px auto 0;

  svg {
    width: 48px;
    height: 48px;

    @media (min-width: ${props => props.theme.screen.md}) {
      width: 28px;
      height: 28px;
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 8px;
      cursor: pointer;
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-top: 0;
  }
`
const FooterColumn = styled.div`
  padding-left: 16px;
  white-space: pre-line;
  max-width: 300px;
  .react-typeform-embed {
    width: 100%;
    padding-top: 16px;
    padding-right: 16px;
    font-family: ${({ theme }) => theme.font.bold};;
    
  }
  
  
  h6 {
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.extraBold};
    color: ${({ theme }) => theme.color.purple};
    letter-spacing: 4px;
    margin: 0%;
  }

  ul {
    list-style: none;
    margin: 24px 0;
    padding: 0;

    li {
      margin-bottom: 8px;
      ${({ theme }) => theme.font_size.regular};

      a {
        color: ${({ theme }) => theme.color.black};
      }
    }
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 100%;
  }
  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 300px;
  }
  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 300px;
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    max-width: 300px;
  }
`
const Copy = styled(Container)`
  ${({ theme }) => theme.font_size.small};
  font-family: ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.color.grey.grey03};
  text-align: left;
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 0 16px;

  @media (min-width: ${props => props.theme.screen.xl}) {
    text-align: center;
  }
  
`

export default injectIntl(Footer)
